// Generated by Framer (e75c18f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["y1GCIN1QV", "WekOGnle0"];

const serializationHash = "framer-wZwO1"

const variantClassNames = {WekOGnle0: "framer-v-ah776h", y1GCIN1QV: "framer-v-16un26t"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "y1GCIN1QV", "Variant 2": "WekOGnle0"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, S4sI1Ey1s: title ?? props.S4sI1Ey1s ?? "Glassland", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "y1GCIN1QV"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, S4sI1Ey1s, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "y1GCIN1QV", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "WekOGnle0") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-16un26t", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"y1GCIN1QV"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({WekOGnle0: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<motion.div className={"framer-v6yykz"} data-framer-name={"Top Line"} layoutDependency={layoutDependency} layoutId={"AR7DQrnNX"} style={{backgroundColor: "rgb(22, 22, 22)"}}/>)}<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "28px", "--framer-letter-spacing": "-1px", "--framer-line-height": "56px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(210, 210, 210))"}}>Glassland</motion.p></React.Fragment>} className={"framer-460ln"} data-framer-name={"Recongroup"} layoutDependency={layoutDependency} layoutId={"F3R_j81TY"} style={{"--extracted-r6o4lv": "rgb(210, 210, 210)", "--framer-paragraph-spacing": "0px"}} text={S4sI1Ey1s} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-wZwO1[data-border=\"true\"]::after, .framer-wZwO1 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-wZwO1.framer-y152d2, .framer-wZwO1 .framer-y152d2 { display: block; }", ".framer-wZwO1.framer-16un26t { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 18px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-wZwO1 .framer-v6yykz { flex: none; height: 1px; overflow: hidden; position: relative; width: 600px; }", ".framer-wZwO1 .framer-460ln { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-wZwO1.framer-16un26t { gap: 0px; } .framer-wZwO1.framer-16un26t > * { margin: 0px; margin-bottom: calc(18px / 2); margin-top: calc(18px / 2); } .framer-wZwO1.framer-16un26t > :first-child { margin-top: 0px; } .framer-wZwO1.framer-16un26t > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 75
 * @framerIntrinsicWidth 600
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"WekOGnle0":{"layout":["auto","auto"]}}}
 * @framerVariables {"S4sI1Ey1s":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerkf_NRKuYO: React.ComponentType<Props> = withCSS(Component, css, "framer-wZwO1") as typeof Component;
export default Framerkf_NRKuYO;

Framerkf_NRKuYO.displayName = "Work";

Framerkf_NRKuYO.defaultProps = {height: 75, width: 600};

addPropertyControls(Framerkf_NRKuYO, {variant: {options: ["y1GCIN1QV", "WekOGnle0"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, S4sI1Ey1s: {defaultValue: "Glassland", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framerkf_NRKuYO, [])